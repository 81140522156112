import React from 'react';
import { useNavigate } from 'react-router-dom';
import gemIcon from '../../static/img/MSLOGO1.jpg'; // Replace with your gemstone-themed icon if available

function NotFound() {
  const navigate = useNavigate();
  return (
    <div className="not-found-page">
      <div className="content-wrapper text-center">
        <img src={gemIcon} alt="Gem Icon" className="gem-icon" />
        <h1 className="display-1">404</h1>
        <h2>Page Not Found</h2>
        <p className="lead">
          Sorry, the page you are looking for doesn’t exist. But don’t worry, our gems are still here for you!
        </p>
        <button
          className="px-btn btn-primary mt-4"
          onClick={() => navigate('/')}
        >
          Return to Home
        </button>
      </div>
    </div>
  );
}
export default NotFound;