import React from 'react';
import { Routes, Route } from "react-router-dom"; 
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './static/plugin/bootstrap/css/bootstrap.min.css'
import './static/plugin/themify-icons/themify-icons.css'
import './static/plugin/owl-carousel/css/owl.carousel.min.css'
import './static/plugin/magnific/magnific-popup.css'
import './static/css/style.css'
import Header from "./markup/Components/Header/Header";
import Home from './markup/pages/Home';
import Footer from './markup/Components/Footer/Footer';
// import About from './markup/pages/About';
import News from './markup/pages/News';
// import Contact from './markup/Components/Contact/Contact';
import NotFound from './markup/pages/404';

// Import your Airdrop components
// import Airdrops from './markup/Components/Airdrops/Airdrops';
import AllAirdrops from './markup/pages/AllAirdrops';
import AirdropDetails from './markup/Components/DetailAirdrop/DetailAirdrop';

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/about" element={<About />} /> */}
        {/* <Route path="/contact" element={<Contact />} /> */}
        
        {/* Add route for Airdrop Portfolio */}
        {/* <Route path="/airdrops" element={<Airdrops />} /> */}
        
        {/* Add route for All Airdrops */}
        <Route path="/airdrops" element={<AllAirdrops />} />
        <Route path="/news" element={<News />} />
        
        {/* Add route for Airdrop Details */}
        <Route path="/airdrops/:airdropId" element={<AirdropDetails />} />
        
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
