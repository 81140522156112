import React from 'react';
import { Carousel } from 'react-bootstrap';
import test4 from '../../../static/img/rahel.jpg';
import test5 from '../../../static/img/miki.jpg';
import test2 from '../../../static/img/nasir.jpg';

function Testimonal() {
    const testimonials = [
        { 
            imgSrc: test4, 
            name: 'Rahel', 
            role: 'Crypto Hunter',
            feedback: 'I’ve been following MasterCrypto for months, and the airdrop opportunities they share are incredible. Every time I participate, I receive great rewards, and the process is always smooth. This channel has truly helped me discover some of the best new projects in crypto!'
        },
        { 
            imgSrc: test5, 
            name: 'Mikiyas', 
            role: 'Crypto Investor',
            feedback: 'As an active crypto investor, I rely on MasterCrypto to stay up-to-date with the latest and most lucrative airdrops. The channel provides trustworthy insights, and the airdrop opportunities are always on point. This community has given me the edge I needed in crypto!'
        },
        { 
            imgSrc: test2, 
            name: 'Nasman Sniper', 
            role: 'Forex Trader',
            feedback: 'Joining MasterCrypto was one of the best decisions I made. The airdrop alerts are always timely, and they help me identify high-potential projects early on. If you\'re serious about crypto, this channel is a must-follow for airdrops and market insights!'
        }
    ];

    return (
        <section className="testimonials">
            <div className="heading text-center">
                <h2>What Our <span>Community</span> Says About MasterCrypto</h2>
            </div>
            <div className="container">
                <Carousel interval={2000} slide={true} fade={false}>
                    {testimonials.map((testimonial, index) => (
                        <Carousel.Item key={index} className="text-center">
                            <img decoding="async" src={testimonial.imgSrc} alt={testimonial.name} className="center-block team" />
                            <h2>{testimonial.name}</h2>
                            <h4>{testimonial.role}</h4>
                            <p>{testimonial.feedback}</p>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </div>
        </section>
    );
}

export default Testimonal;
