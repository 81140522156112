import React, { useState } from 'react';
import './AskedQ.css'

function AskedQ() {
  const [activeIndex, setActiveIndex] = useState(null);

  // Function to toggle the answer
  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      question: "What is Airdrop?",
      answer: "An airdrop is a free distribution of cryptocurrency tokens to users, often as a marketing strategy or reward. Tokens are sent directly to wallets, sometimes requiring users to meet specific criteria or complete tasks..",
    },
    {
      question: "Can we make money from Airdrops?",
      answer: "Yes, you can make money with airdrops by receiving free tokens and selling them if they gain value. Be cautious of scams and focus on legit projects.",
    },
    {
      question: "Can we farm airdrops with multiple accounts?",
      answer: "Yes, you can farm with multiple accounts, but it’s often against the rules. If caught, you risk disqualification or bans.",
    },
    // {
    //   question: "What services does MasterCrypto offer?",
    //   answer: "MasterCrypto offers resources, tools, and guidance on cryptocurrency investments and trading.",
    // },
    {
      question: "Can I join the MasterCrypto community?",
      answer: "Yes, we welcome crypto enthusiasts to join our growing community for updates, tips, and airdrop opportunities.",
    },
    {
      question: "Are the airdrop links shared by MasterCrypto legit?",
      answer: "Yes, we ensure that all shared links are verified and safe for the community to use.",
    },
  ];

  return (
    <section id="faq" className="section after-left-section">
      <div className="container">
        <div className="sm-title">
          <h4 className="font-alt text-center">Frequently Asked Questions</h4>
        </div>
        <div className="row">
          {/* Left Column */}
          <div className="col-md-6">
            {faqData.slice(0, 3).map((item, index) => (
              <div key={index} className="faq-item">
                <h5
                  className={`faq-question ${activeIndex === index ? 'active' : ''}`}
                  onClick={() => toggleAnswer(index)}
                >
                  {item.question}
                </h5>
                {activeIndex === index && (
                  <p className="faq-answer">{item.answer}</p>
                )}
              </div>
            ))}
          </div>
          {/* Right Column */}
          <div className="col-md-6">
            {faqData.slice(3, 6).map((item, index) => (
              <div key={index + 3} className="faq-item">
                <h5
                  className={`faq-question ${activeIndex === index + 3 ? 'active' : ''}`}
                  onClick={() => toggleAnswer(index + 3)}
                >
                  {item.question}
                </h5>
                {activeIndex === index + 3 && (
                  <p className="faq-answer">{item.answer}</p>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default AskedQ;
