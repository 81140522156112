import React from 'react';
import ceo from '../../../static/img/logoabout.jpg';
import { useNavigate } from 'react-router-dom';
import './About.css';  // Import the updated CSS

function About() {
  const navigate = useNavigate();
  return (
    <div>
      <section id="about" className="section about-section after-left-section">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6 my-3">
              <div className="img-box">
                <img src={ceo} alt="About mastercrypto" className="ceo-img" />
              </div>
            </div>
            <div className="col-lg-6 my-3">
              <div className="typo-box">
                <h3>About Our Community</h3>
                <p>
                  At <strong>mastercrypto</strong>, we empower our community with tools and insights to maximize their cryptocurrency journey. Our mission is to connect you with legitimate and rewarding airdrop opportunities. 
                  <br /><br />
                  We share verified airdrop links, ensuring you only engage with trusted projects. Our platform is designed to guide users on how to farm airdrops effectively, helping you accumulate valuable tokens with minimal risk. From completing simple tasks to holding specific tokens, our step-by-step resources make farming airdrops straightforward and rewarding.
                  <br /><br />
                  By joining our community, you become part of a growing network of crypto enthusiasts who value transparency, collaboration, and success in the blockchain space. Let us help you turn free tokens into a foundation for your crypto portfolio.
                </p>
                {/* <button
                  className="btn btn-primary"
                  onClick={() => navigate('/About')} // Navigate to the About page
                >
                  Learn More About Us
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default About;
