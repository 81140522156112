import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './DetailAirdrop.css';

function AirdropDetails() {
    const { airdropId } = useParams(); // Get the airdropId from the URL params
    const [airdrop, setAirdrop] = useState(null);

    useEffect(() => {
        const fetchAirdropDetails = async () => {
            try {
                const response = await fetch(`https://masterapi.mastercrypto.org/airdrops/${airdropId}`);
                const data = await response.json();
                setAirdrop(data);
            } catch (error) {
                console.error('Error fetching airdrop details:', error);
            }
        };

        fetchAirdropDetails();
    }, [airdropId]); // Refetch whenever airdropId changes

    if (!airdrop) {
        return <div className="loading-spinner">Loading...</div>; // Show loading spinner if data is not yet loaded
    }

    // Split the airdrop description into steps
    const steps = airdrop.airdrop_description.split('.').filter((step) => step.trim() !== '');

    return (
        <section className="airdrop-details">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="card">
                            <h3 className="airdrop-title">{airdrop.airdrop_name}</h3>
                            <img className="airdrop-image" src={airdrop.airdrop_img} alt={airdrop.airdrop_name} />
                            <h4 className="task-header">Task Steps:</h4>
                            <ol className="task-steps">
                                {steps.map((step, index) => (
                                    <li key={index} className="step-item">
                                        {step.trim()}.
                                    </li>
                                ))}
                            </ol>
                            <a
                                href={airdrop.airdrop_link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="join-btn"
                            >
                                Join Airdrop
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AirdropDetails;
