import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function AirdropPortfolio() {
    const [airdrops, setAirdrops] = useState([]);

    useEffect(() => {
        const fetchAirdrops = async () => {
            try {
                const response = await fetch('https://masterapi.mastercrypto.org/airdrops');
                const data = await response.json();
                const sortedData = data.sort((a, b) => b.airdrop_id - a.airdrop_id);
                setAirdrops(sortedData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchAirdrops();
    }, []);

    return (
        <section id="work" className="section gray-bg">
            <div className="container">
                <div className="row justify-content-center section-title text-center">
                    <div className="col-lg-7">
                        <h3 className="font-alt"> Airdrops</h3>
                        <p>We offer a variety of exciting airdrops for our users, featuring exclusive giveaways and rewards.</p>
                    </div>
                </div>

                <div className="portfolio-content row lightbox-gallery">
                    {airdrops.slice(0, 9).map((airdrop) => (
                        <div key={airdrop.airdrop_id} className="col-sm-6 col-lg-4 grid-item">
                            <div className="portfolio-box-01">
                                <div className="portfolio-info">
                                    <h5>{airdrop.airdrop_name}</h5>
                                </div>
                                <div className="portfolio-img">
                                    <Link to={`/airdrops/${airdrop.airdrop_id}`}>
                                        <img src={airdrop.airdrop_img} alt={airdrop.airdrop_name} />
                                    </Link>
                                </div>
                                {/* Button with Icon */}
                                <Link to={`/airdrops/${airdrop.airdrop_id}`} className="btn btn-primary mt-2">
                                    <i className="fas fa-info-circle me-2"></i> View Details
                                </Link>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="row justify-content-center mt-4">
                    <Link to="/airdrops" className="btn btn-primary">
                        More Airdrops
                    </Link>
                </div>
            </div>
        </section>
    );
}

export default AirdropPortfolio;
