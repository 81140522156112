import { useEffect, useState } from 'react';

function MasterCryptoYouTube() {
    const [youTubeVideos, setYouTubeVideos] = useState([]);

    useEffect(() => {
        fetch(
            "https://youtube.googleapis.com/youtube/v3/search?part=snippet&channelId=UCypdWadVuy_ZWeKPHwsTu9g&maxResults=18&order=date&key=AIzaSyCd0tZxcuGHC7TbasY8KQp_YHv5FD2Keyo"
        )
            .then((response) => response.json())
            .then((data) => {
                setYouTubeVideos(data.items);
            })
            .catch((error) => {
                console.error("Error fetching YouTube videos:", error);
            });
    }, []);

    return (
        <section className="youtubeVideosWrapper">
            <div className="allVideosWrapper">
                <div className="container">
                    <div className="row justify-content-center text-center">
                        <div className="col-12">
                            <div className="title-wraper">
                                Latest Videos <br />
                                <br />
                            </div>
                        </div>

                        {youTubeVideos?.map((singleVideo, i) => {
                            let vidId = singleVideo.id.videoId;
                            let vidLink = `https://www.youtube.com/watch?v=${vidId}`;
                            return (
                                <div key={i} className="col-sm-12 col-md-6 col-lg-4">
                                    <div className="singleVideoWrapper">
                                        <div className="videoThumbnail">
                                            <a href={vidLink} target="_blank" rel="noreferrer">
                                                <img
                                                    src={singleVideo.snippet.thumbnails.high.url}
                                                    alt="thumbnails"
                                                />
                                            </a>
                                        </div>
                                        <div className="videoInfoWrapper">
                                            <div className="videoTitle">
                                                <a href={vidLink} target="_blank" rel="noreferrer">
                                                    {singleVideo.snippet.title}
                                                </a>
                                            </div>
                                            <div className="videoDesc">
                                                {singleVideo.snippet.description}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MasterCryptoYouTube;
