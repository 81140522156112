import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function AllAirdrops() {
    const [airdrops, setAirdrops] = useState([]);

    useEffect(() => {
        const fetchAirdrops = async () => {
            try {
                const response = await fetch('https://masterapi.mastercrypto.org/airdrops');
                const data = await response.json();
                // Sort the data if required
                const sortedData = data.sort((a, b) => b.airdrop_id - a.airdrop_id);
                setAirdrops(sortedData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchAirdrops();
    }, []);

    return (
        <section id="all-airdrops" className="section gray-bg">
            <div className="container">
                <div className="row justify-content-center section-title text-center">
                    <div className="col-lg-7">
                        <h3 className="font-alt">All Airdrops</h3>
                        <p>Discover all the amazing airdrops we have to offer.</p>
                    </div>
                </div>

                <div className="portfolio-content row lightbox-gallery">
                    {airdrops.map((airdrop) => (
                        <div key={airdrop.airdrop_id} className="col-sm-6 col-lg-4 grid-item">
                            <div className="portfolio-box-01">
                                <div className="portfolio-info">
                                    <h5>{airdrop.airdrop_name}</h5>
                                </div>
                                <div className="portfolio-img">
                                <Link to={`/airdrops/${airdrop.airdrop_id}`} className="portfolio-img">
    <img src={airdrop.airdrop_img} alt={airdrop.airdrop_name} />
</Link>
                                </div>
                                <Link to={`/airdrops/${airdrop.airdrop_id}`} className="portfolio-link-btn">
                                    View Airdrop
                                </Link>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default AllAirdrops;
