import React from "react";
import { FaTwitter, FaTelegramPlane, FaTiktok, FaYoutube } from "react-icons/fa";
import Carousel from "react-bootstrap/Carousel";
import "./Hero.css";
import bg1 from "../../../static/img/Image/Bg7.jpg";
import bg3 from "../../../static/img/Image/bg2.jpg";

const HeroSection = () => {
  const slides = [
    {
      title: "Exclusive Airdrop",
      subtitle: "Claim Your Free Crypto and Start Building Wealth Today",
      bgImage: bg1,
    },
    {
      title: "Earn Big with Airdrops",
      subtitle: "Don’t Miss the Opportunity to Grow",
      bgImage: bg3,
    },
  ];

  // Function to initialize particles.js
 

  return (
    <section id="home" className="home-banner">
      {/* Particles.js Foreground */}
    

      <Carousel
        controls={false}
        indicators={false}
        interval={5000}
        fade
        pause={false} // Ensure it continues scrolling even on hover
      >
        {slides.map((slide, index) => (
          <Carousel.Item
            key={index}
            style={{
              backgroundImage: `url(${slide.bgImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              height: "100vh",
            }}
          >
            <div className="overlay"></div>
            <div className="container d-flex flex-column justify-content-center align-items-center text-center h-100">
              <h1 className="text-light display-3 fw-bold">{slide.title}</h1>
              <p className="text-light fs-4">{slide.subtitle}</p>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>

      {/* Social Icons */}
      <div className="nav social-icons">
        <a href="https://x.com/Al_Habeshee" target="_blank" rel="noopener noreferrer">
          <FaTwitter size={30} color="#1DA1F2" />
          <span className="community-count">3K Followers</span>
        </a>
        <a href="https://t.me/mastercryptoet" target="_blank" rel="noopener noreferrer">
          <FaTelegramPlane size={30} color="#0088cc" />
          <span className="community-count">40k+ Members</span>
        </a>
        <a href="https://www.tiktok.com/@master_crypto_et" target="_blank" rel="noopener noreferrer">
          <FaTiktok size={30} color="#000000" />
          <span className="community-count">90K+ Followers</span>
        </a>
        <a href="https://www.youtube.com/@MasterCrypto-pl3ub" target="_blank" rel="noopener noreferrer">
          <FaYoutube size={30} color="#FF0000" />
          <span className="community-count">12k+ Sub</span>
        </a>
      </div>

      {/* Scroll Button */}
      <div className="scroll-bottom">
        <a href="#about" className="scroll-btn">
          A
        </a>
      </div>
    </section>
  );
};

export default HeroSection;
