import React from 'react';
import '../../../static/css/style.css'; // Ensure you have your CSS file imported
// import Portfolio from '../Portfolio/Portfolio';
import { Carousel } from 'react-bootstrap';
// Importing images          
import { useNavigate } from 'react-router-dom'; // Import useNavigate                                                    
// import logo from '../../../static/img/logo.png';  
import bg6 from '../../../static/img/Image/bg1.jpg';
import ceo from '../../../static/img/Image/MrAmir.jpg';
import bg14 from '../../../static/img/Image/bg1.jpg';
import bg12 from '../../../static/img/Image/bg1.jpg';
import bg4 from '../../../static/img/Image/bg1.jpg';
import bg13 from '../../../static/img/Image/bg1.jpg';
import bg8 from '../../../static/img/Image/bg1.jpg';
import bg9 from '../../../static/img/Image/bg1.jpg';
import bg16 from '../../../static/img/Image/bg1.jpg';
import HeroSection from '../Herosection/HeroSection';
import About from '../About/About'
import Testimonals from '../Testimonals/Testimonal';
import News from '../News/News';
import AskedQ from '../AskedQ/AskedQ';
import Airdrops from '../Airdrops/Airdrops';

const Home = () => {
    const navigate = useNavigate(); // Initialize useNavigate hook
  
  return (
    <div>
      {/* page loading */}
      {/* <div id="loading">
        <div className="load-circle"><span className="one"></span></div>
      </div> */}
      {/* End */}

      {/* Header */}
      
      {/* End Header */}

      <main>
        {/* Home Banner */}
      <HeroSection />
        {/* End Home Banner */}

        {/* About Us */}
     <About />

        {/* End About Me */}

        {/* Airdrops start from here */}
        <Airdrops />
        {/* Airdrops end here */}
{/* video start */}
    {/* Fullscreen Video Section  from hagere tv */}
   
{/* video end */}
        {/* Testimonials */}
       <Testimonals />
        {/* End Testimonials */}
   {/* news start  */}
        <News />
      {/*  news end */}
        {/* Contact Us */}
       <AskedQ />
        {/* End Contact Us */}
   
        {/* Footer */}
     
        {/* End Footer */}
      </main>
    </div>
  );
};

export default Home;
