import React from 'react';
import { FaInstagram, FaLinkedinIn, FaWhatsapp, FaBitcoin, FaEthereum, FaCcStripe, FaTelegram, FaTwitter, FaTiktok, FaYoutube } from 'react-icons/fa';

function Footer() {
  return (
    <footer className="footer">
      <div className="container py-4">
        <div className="row align-items-center">
          {/* Social Media Section */}
          <div className="col-md-6 mb-3 mb-md-0 text-center text-md-left">
            <h5 className="mb-3">Join Us:</h5>
                <a
                href="https://x.com/Al_Habeshee"
                aria-label="Instagram"
                className="social-icon"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTwitter />
              </a>
              <a
                href="https://t.me/mastercryptoet"
                aria-label="Telegram"
                className="social-icon"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTelegram />
              </a>
              <a
                href="https://wa.me/yourwhatsappnumber"
                aria-label="Tiktok"
                className="social-icon"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTiktok />
              </a>
              <a
                href="https://www.youtube.com/@MasterCrypto-pl3ub"
                aria-label="Youtube"
                className="social-icon"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaYoutube />
              </a>
            <div className="social-icons">
          
            </div>
          </div>

          {/* Cryptocurrency Icons Section */}
          <div className="col-md-6 mb-3 mb-md-0 text-center text-md-right">
            <h5 className="mb-3">Supported Cryptos:</h5>
            <div className="crypto-icons">
              <span aria-label="Bitcoin" className="crypto-icon">
                <FaBitcoin />
              </span>
              <span aria-label="Ethereum" className="crypto-icon">
                <FaEthereum />
              </span>
              <span aria-label="Stripe" className="crypto-icon">
                <FaCcStripe />
              </span>
            </div>
          </div>
        </div>

        {/* Footer Credits */}
        <div className="row mt-4">
          <div className="col-12 text-center">
            <p>© 2024 MasterCrypto. All rights reserved.</p>
            <p>
               Developed by
              <a
                href="https://nati16.com"
                target="_blank"
                rel="noopener noreferrer"
                className="designer-link"
              >
                {' '}
                Nati
              </a> <br />
                  <a
                href="https://t.me/Boaz_Coder"
                aria-label="Telegram"
                className="social-icon"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTelegram />
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
